import type {Response} from '../../../types/response'
import {NavigationHighlight} from '../../../types/content-types'
import {Paragraph} from '../../../types/paragraphs'
import GenericLink from '../../shared/Link'

type Props = {
  highlights?: Paragraph[] | null
  response: Omit<Response, 'data' | 'meta' | 'links'> | null
}

const NavigationHighlightComp = ({highlights, response}: Props) => {
  const highlightsFull = response?.included?.filter(
    el =>
      el.type === 'paragraph--navigation_highlight' &&
      highlights?.find(highlight => highlight.id === el.id),
  ) as NavigationHighlight[]
  if (!highlightsFull || highlightsFull.length === 0) {
    return null
  }
  return (
    <div className="c-navbar__subnav-banner ml-auto">
      {highlightsFull?.map(highlight =>
        highlight.attributes.field_title &&
        highlight.attributes.field_short_description &&
        highlight.attributes.field_button ? (
          <div
            key={highlight.id}
            className="c-navbar__subnav-banner-textWrapper"
          >
            <div className="d-flex flex-column">
              <span className="c-navbar__subnav-banner-textWrapper__title">
                {highlight.attributes.field_title}
              </span>
              <p className="c-navbar__subnav-banner-textWrapper__description">
                {highlight.attributes.field_short_description}
              </p>
              <GenericLink
                link={highlight.attributes.field_button}
                extraClass="c-navbar__subnav-banner-textWrapper__link"
              >
                {highlight.attributes.field_button?.title}
              </GenericLink>
            </div>
          </div>
        ) : null,
      )}
    </div>
  )
}

export default NavigationHighlightComp
