import {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import GenericLink from '../../shared/Link'
import striptags from 'striptags'
import {Response} from '../../../types/response'

type Props = {
  languageSwitchMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
}

const LanguageSwitch = ({languageSwitchMenuItems}: Props) => {
  const {t} = useTranslation('common')
  const [showLanguageList, setShowLanguageList] = useState(false)

  const handleToggle = useCallback(() => {
    setShowLanguageList(prev => !prev)
  }, [])

  const sortedlanguageSwitchMenuItems = useMemo(() => {
    return (
      languageSwitchMenuItems?.included?.sort((a, b) => {
        return a.attributes.weight! - b.attributes.weight!
      }) || []
    )
  }, [languageSwitchMenuItems])

  const renderDefaultLanguageMenuItem = useCallback(() => {
    const defaultLanguageSwitchMenuItem =
      sortedlanguageSwitchMenuItems?.length > 0
        ? sortedlanguageSwitchMenuItems.find(
            item => item.attributes.field_default_language,
          )
        : null

    if (!defaultLanguageSwitchMenuItem) return null
    const imageSrc = defaultLanguageSwitchMenuItem.attributes.imageSrc

    return (
      <button
        className="c-nav-language-switch d-flex flex-row align-items-baseline ml-lg-3 p-0 border-0"
        type="button"
        onClick={handleToggle}
      >
        {imageSrc ? (
          <Image
            src={
              imageSrc.indexOf(process.env.NEXT_PUBLIC_API_URL!) < 0
                ? `${process.env.NEXT_PUBLIC_API_URL}${imageSrc}`
                : imageSrc
            }
            alt="flag"
            width={34}
            height={20}
            className="c-nav-language-switch__flag d-flex align-items-baseline"
          ></Image>
        ) : null}
        <span className="c-nav-language-switch__country-code">
          {defaultLanguageSwitchMenuItem?.attributes.field_country_code}
        </span>
        <span className="c-nav-language-switch__arrow"></span>
      </button>
    )
  }, [handleToggle, sortedlanguageSwitchMenuItems])

  const renderLanguageMenuItem = useCallback(() => {
    return sortedlanguageSwitchMenuItems?.length > 0
      ? sortedlanguageSwitchMenuItems
          .filter(item => !item.attributes.field_default_language)
          .map(menu => (
            <li key={menu.id} className="d-flex">
              <GenericLink
                link={menu.attributes.link}
                extraClass="c-language-list__cta d-flex justify-content-center align-items-center"
              >
                {menu.attributes.imageSrc ? (
                  <Image
                    src={
                      menu.attributes.imageSrc.indexOf(
                        process.env.NEXT_PUBLIC_API_URL!,
                      ) < 0
                        ? `${process.env.NEXT_PUBLIC_API_URL}${menu.attributes.imageSrc}`
                        : menu.attributes.imageSrc
                    }
                    alt="flag"
                    layout="fixed"
                    width={34}
                    height={20}
                  ></Image>
                ) : null}

                <span className="c-language-list__name">
                  {menu.attributes.title}
                </span>
              </GenericLink>
            </li>
          ))
      : null
  }, [sortedlanguageSwitchMenuItems])

  const renderLanguageList = useCallback(() => {
    return showLanguageList ? (
      <div
        className="c-language-list__backdrop position-absolute w-100 mw-100 vh-100"
        onClick={handleToggle}
        role="button"
        aria-hidden="true"
      >
        <div className="c-language-list position-absolute">
          <button
            className="c-language-list__close position-absolute d-lg-none"
            aria-label="close"
            type="button"
          >
            <svg
              width="19"
              height="19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#fff"
                d="m5.421 3.614 9.036 9.036-1.808 1.807-9.035-9.035z"
              />
              <path
                fill="#fff"
                d="m14.456 5.42-9.035 9.036-1.808-1.807 9.036-9.036z"
              />
            </svg>
          </button>
          <p className="c-language-list__title text-center">
            {striptags(t('language_list_title'))}
          </p>
          <ul className="c-language-list__list d-lg-block d-flex flex-column align-items-stretch justify-content-center p-0 m-0">
            {renderLanguageMenuItem()}
          </ul>
        </div>
      </div>
    ) : null
  }, [showLanguageList, handleToggle, t, renderLanguageMenuItem])

  return (
    <>
      {renderDefaultLanguageMenuItem()}

      {renderLanguageList()}
    </>
  )
}

export default LanguageSwitch
