import {ReactNode} from 'react'

type Props = {
  children: ReactNode
  condition?: boolean
  wrapper: any
}

const ConditionalSection = ({condition, wrapper, children}: Props) =>
  condition ? wrapper(children) : children

export default ConditionalSection
