import Image from 'next/image'
import {
  MediaImage,
  MediaImageInner,
  MediaVideoInner,
} from '../../types/paragraphs'
import ConditionalWrapper from './ConditionalWrapper'
import type {Response} from '../../types/response'
import classNames from 'classnames'

type Props = {
  item?: MediaImage | MediaVideoInner
  wrapperClass?: string
  imageClass?: string
  layout?: 'intrinsic' | 'fixed' | 'responsive' | 'fill'
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  priority?: boolean
  width?: number
  height?: number
  sizes?: string
  response?:
    | Response[]
    | Response
    | null
    | Omit<Response, 'data' | 'meta' | 'links'>
    | null
}

const MediaItem = ({
  item,
  layout = 'intrinsic',
  wrapperClass = '',
  imageClass = '',
  objectFit,
  priority = false,
  width,
  height,
  response,
  sizes,
}: Props) => {
  if (
    !item ||
    (item.type !== 'media--video' && !(item as MediaImage).relationships)
  ) {
    return null
  }
  const isVideo = item.type === 'media--video'
  const mediaImageInner: MediaImageInner | undefined =
    item.type !== 'media--video'
      ? (Array.isArray(response)
          ? response.map(el => el.included).flat()
          : response?.included
        )?.find(
          el =>
            el.id ===
            (item as MediaImage).relationships.field_media_image?.data?.id,
        )
      : undefined
  return (
    <ConditionalWrapper
      condition={!!wrapperClass}
      wrapper={(childrenPassed: any) => (
        <div className={wrapperClass}>{childrenPassed}</div>
      )}
    >
      {isVideo ? (
        <div className="video-wrapper">
          <div className="video-responsive">
            <iframe
              src={`${(
                item as MediaVideoInner
              ).attributes.field_media_oembed_video
                .replace('youtube.com', 'youtube-nocookie.com')
                .replace('watch?v=', 'embed/')}?rel=0&enablejsapi=1`}
              title={(item as MediaVideoInner).attributes.name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowFullScreen
            />
          </div>
        </div>
      ) : (
        <>
          {mediaImageInner ? (
            <Image
              src={
                mediaImageInner.attributes.uri.url.indexOf(
                  process.env.NEXT_PUBLIC_API_URL!,
                ) < 0
                  ? `${process.env.NEXT_PUBLIC_API_URL}${mediaImageInner.attributes.uri.url}`
                  : mediaImageInner.attributes.uri.url
              }
              width={
                layout === 'fill'
                  ? undefined
                  : width ??
                    (item as MediaImage).relationships.thumbnail.data.meta
                      ?.width!
              }
              height={
                layout === 'fill'
                  ? undefined
                  : height ??
                    (item as MediaImage).relationships.thumbnail.data.meta
                      ?.height!
              }
              sizes={sizes}
              fill={layout === 'fill'}
              className={classNames(
                imageClass,
                objectFit ? `object-fit-${objectFit}` : '',
              )}
              priority={priority}
              alt={(item as MediaImage).relationships.thumbnail.data.meta?.alt}
            />
          ) : null}
        </>
      )}
    </ConditionalWrapper>
  )
}

export default MediaItem
