import React from 'react'
import Nav from 'react-bootstrap/Nav'
import {MenuLink} from '../../../types/content-types'
import GenericLink from '../../shared/Link'

type Props = {
  footerMenuItems?: MenuLink[]
  lightVersion?: boolean
}

const FooterNavigation: React.FC<Props> = ({
  footerMenuItems,
  lightVersion = false,
}: Props) => (
  <Nav className="c-footer__nav">
    <ul
      className={`c-footer__nav-items ${
        lightVersion ? '' : 'justify-content-lg-end'
      }`}
    >
      {footerMenuItems
        ?.filter(el => el.attributes.enabled)
        .sort((a, b) => a.attributes.weight! - b.attributes.weight!)
        .map(menuItem => (
          <li className="c-footer__nav-item" key={menuItem.id}>
            <GenericLink link={menuItem.attributes.link}>
              {menuItem.attributes.title}
            </GenericLink>
          </li>
        ))}
    </ul>
  </Nav>
)

export default FooterNavigation
