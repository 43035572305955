import {FormEvent, useCallback, useState} from 'react'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import striptags from 'striptags'
import {MenuLink} from '../../../types/content-types'
import algoliasearch from 'algoliasearch/lite'
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import {
  Configure,
  InstantSearch,
  connectAutoComplete,
} from 'react-instantsearch-dom'
import type {AutocompleteProvided} from 'react-instantsearch-core'

declare const window: any

type Props = {
  searchItem?: MenuLink
  isMobile?: boolean
}

type searchSuggestionsProps = AutocompleteProvided & {
  searchItem: MenuLink | undefined
  navigateToSearchPage: any
}

interface CustomElements extends HTMLFormControlsCollection {
  search: HTMLInputElement
}
interface CustomForm extends HTMLFormElement {
  readonly elements: CustomElements
}

const iconButtonStyles = {
  padding: '0.5rem',
  width: '3rem',
  height: '3rem',
  position: 'relative',
  boxShadow: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  '&::before': {
    content: '""',
    width: '20px',
    height: '20px',
    display: 'block',
    border: 'none',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.028 16.805a7.778 7.778 0 1 0 0-15.555 7.778 7.778 0 0 0 0 15.555ZM18.75 18.75l-4.229-4.23' stroke='%23303642' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}

const SearchSuggestions = connectAutoComplete(
  ({
    hits,
    refine,
    searchItem,
    navigateToSearchPage,
  }: searchSuggestionsProps) => {
    const {t} = useTranslation('common')
    const [inputVal, setInputVal] = useState('')
    return (
      <Autocomplete
        freeSolo
        disablePortal
        sx={{width: '100%'}}
        filterOptions={x => x}
        options={inputVal.length > 0 ? hits?.map(hit => hit.query) : []}
        autoComplete
        includeInputInList
        filterSelectedOptions
        disableClearable
        noOptionsText={t('search_autocomplete_no_results')}
        onInputChange={(_event, newInputValue) => {
          setInputVal(newInputValue)
          refine(newInputValue)
        }}
        onChange={(_event, value) => navigateToSearchPage(value)}
        renderInput={params => (
          <>
            <TextField
              {...params}
              name="search"
              placeholder={searchItem?.attributes.title}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      type="submit"
                      aria-label="Search"
                      sx={iconButtonStyles}
                    />
                  </InputAdornment>
                ),
                type: 'search',
              }}
            />
          </>
        )}
      />
    )
  },
)

const Search = ({searchItem, isMobile = false}: Props) => {
  const {t} = useTranslation()
  const router = useRouter()

  const navigateToSearchPage = useCallback(
    (searchValue: string) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'search',
        searchValue,
      })
      router.push(`${striptags(t('training_search_url'))}?query=${searchValue}`)
    },
    [router, t],
  )

  const onSubmit = useCallback(
    (e: FormEvent<CustomForm>) => {
      const searchTerm = e.currentTarget.elements.search.value
      e.preventDefault()
      navigateToSearchPage(searchTerm)
    },
    [navigateToSearchPage],
  )
  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!,
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY!,
  )

  const renderSearchSuggestion = useCallback(() => {
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={
          process.env
            .NEXT_PUBLIC_ALGOLIA_SUBSCRIPTION_TRAINING_QUERY_SUGGESTIONS_INDEX!
        }
      >
        <Configure
          filters={`${process.env.NEXT_PUBLIC_ALGOLIA_SUBSCRIPTION_TRAINING_INDEX}.facets.exact_matches.search_api_language.value:${router.locale}`}
        />
        <form
          className={`w-75 mw-75 c-navbar__functional-nav-search ${
            isMobile ? 'mobile' : ''
          }`}
          onSubmit={onSubmit}
        >
          <SearchSuggestions
            searchItem={searchItem}
            navigateToSearchPage={navigateToSearchPage}
          />
        </form>
      </InstantSearch>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, searchItem])

  return <>{renderSearchSuggestion()}</>
}

export default Search
